/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * RTL-ized by Arash Laylazi (https://github.com/PerseusTheGreat)
 * RTL rev. 16 (https://github.com/PerseusTheGreat/bootstrap-rtl)
 */

@import "functions";
@import "variables-rtl";
@import "mixins-rtl";
@import "root";
@import "reboot-rtl";
@import "type-rtl";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms-rtl";
@import "buttons";
@import "transitions";
@import "dropdown-rtl";
@import "button-group-rtl";
@import "input-group-rtl";
@import "custom-forms-rtl";
@import "nav-rtl";
@import "navbar-rtl";
@import "card-rtl";
@import "breadcrumb-rtl";
@import "pagination-rtl";
@import "badge";
@import "jumbotron";
@import "alert-rtl";
@import "progress";
@import "media";
@import "list-group-rtl";
@import "close-rtl";
@import "toasts";
@import "modal-rtl";
@import "tooltip";
@import "popover-rtl";
@import "carousel-rtl";
@import "spinners-rtl";
@import "utilities";
@import "print";
